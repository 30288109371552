let PrivacyPolicy1 = `<p>
<span style="color: var(--tw-prose-bold);">Last Updated:</span>11-01-2024</p>
<p>
<span style="color: var(--tw-prose-bold);">About Fleetio:</span>
</p>
<p>
<span style="color: var(--tw-prose-bold);">Fleetio provides fleet management software as a service to track, analyze, and improve fleet operations.</span>
</p>
<p>
<span style="color: var(--tw-prose-bold);">Types of Information Collected:</span>
</p>
<ul>
<li>
    <span style="color: var(--tw-prose-bold);">Personal Information:</span>Directly associated with a specific person or entity, including names, email addresses, usernames, passwords, and payment information.</li>
<li>
    <span style="color: var(--tw-prose-bold);">Non-Personal Information:</span>Information not directly associated with a specific person or entity.</li>
</ul>
<p>
<span style="color: var(--tw-prose-bold);">Whose Personal Information is Processed:</span>
</p>
<ul>
<li>
    <span style="color: var(--tw-prose-bold);">Visitors:</span>Individuals visiting Fleetio's website.</li>
<li>
    <span style="color: var(--tw-prose-bold);">Customers:</span>Users of Fleetio's software.</li>
</ul>
<p>
<span style="color: var(--tw-prose-bold);">How Information is Collected:</span>
</p>
<ul>
<li>
    <span style="color: var(--tw-prose-bold);">Visitor Information:</span>Collected through website visits, including IP addresses, access times, browser type, and language.</li>
<li>
    <span style="color: var(--tw-prose-bold);">Customer Information:</span>Collected when becoming a customer, including names, contact details, and organizational information.</li>
</ul>
<p>
<span style="color: var(--tw-prose-bold);">Information Usage:</span>
</p>
<ul>
<li>
    <span style="color: var(--tw-prose-bold);">Visitor Information:</span>Used to track engagement and improve user experience.</li>
<li>
    <span style="color: var(--tw-prose-bold);">Customer Information:</span>Used for various purposes, including processing requests, billing transactions, providing information or services, and marketing.</li>
</ul>
<p>
<span style="color: var(--tw-prose-bold);">Information Sharing and Disclosure:</span>
</p>
<ul>
<li>Personal Information is not traded or sold to third parties.</li>
<li>Shared with third parties for services like maintenance, web analytics, and payment processing.</li>
<li>Disclosed in compliance with legal processes, court orders, or to protect Fleetio's rights.</li>
</ul>
<p>
<span style="color: var(--tw-prose-bold);">Cross-Border Data Transfers:</span>
</p>
<ul>
<li>Fleetio processes Personal Information in the United States and may transfer data across borders.</li>
</ul>
<p>
<span style="color: var(--tw-prose-bold);">Data Retention:</span>
</p>
<ul>
<li>Personal Information is retained as long as necessary for the intended purpose or as required by law.</li>
</ul>
<p>
<span style="color: var(--tw-prose-bold);">Security Measures:</span>
</p>
<ul>
<li>Fleetio takes reasonable steps to protect information, using encryption for sensitive data.</li>
<li>Account access is secured with individual usernames and passwords.</li>
</ul>
<p>
<span style="color: var(--tw-prose-bold);">Communication and Marketing:</span>
</p>
<ul>
<li>Communication includes updates, legal documents, and customer support.</li>
<li>Opt-out options are provided for marketing communication.</li>
</ul>
<p>
<span style="color: var(--tw-prose-bold);">Breach Protocol:</span>
</p>
<ul>
<li>Users are notified of data breaches within 72 business hours.</li>
</ul>
<p>
<span style="color: var(--tw-prose-bold);">Non-U.S. Users:</span>
</p>
<ul>
<li>Complies with GDPR rights for EU users.</li>
<li>Adheres to the EU-U.S. Privacy Shield Framework.</li>
</ul>
<p>
<span style="color: var(--tw-prose-bold);">Cookie Policy:</span>
</p>
<ul>
<li>Uses cookies for various purposes, including user preferences, analytics, and video tracking.</li>
<li>Provides information on cookies set by Fleetio and third parties.</li>
</ul>
<p>
<span style="color: var(--tw-prose-bold);">Questions and Contact Information:</span>
</p>
<ul>
<li>Users can contact Fleetio for questions or clarifications regarding the privacy policy.</li>
<li>
    <br>
</li>
</ul>`;

const PrivacyPolicy = `<p><strong><span style="font-size:12pt;">Privacy Policy of Fleetio (Last Updated: 11-01-2024)</span></strong></p>
<p class="heading"><strong><span style="font-size:12pt;">About Fleetio:</span></strong></p>
<p><span style="font-size:12pt;">Fleetio is a provider of cutting-edge fleet management software as a service, dedicated to the meticulous tracking, comprehensive analysis, and continual improvement of fleet operations.</span></p>
<p class="heading"><strong><em><span style="font-size:12pt;">Types of Information Collected:</span></em></strong></p>
<p class="heading"><span style="font-size:12pt;">&nbsp; &nbsp; &nbsp; <strong>&nbsp; Personal Information:</strong></span></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Refers to data directly associated with a specific person or entity.</span></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Encompasses names, email addresses, usernames, passwords, and payment information.</span></p>
        <p class="heading"><strong><em><span style="font-size:12pt;">Non-Personal Information:</span></em></strong></p>
        <p></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Comprises data not directly linked to a specific person or entity.</span></p>
    </li>
</ul>
<p class="heading"><strong><span style="font-size:12pt;">Whose Personal Information is Processed:</span></strong></p>
<p><strong><em></em></strong></p>
<p class="heading"><strong><em><span style="font-size:12pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Visitors:</span></em></strong></p>
<p></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Encompasses individuals visiting Fleetio&apos;s website.</span></p>
        <p class="heading"><strong><em><span style="font-size:12pt;">Customers:</span></em></strong></p>
        <p></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Involves users actively engaged with Fleetio&apos;s software.</span></p>
    </li>
</ul>
<p class="heading"><strong><span style="font-size:12pt;">How Information is Collected:</span></strong></p>
<p><strong><em></em></strong></p>
<p class="heading"><strong><em><span style="font-size:12pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Visitor Information:</span></em></strong></p>
<p></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Gathered through website visits, including details such as IP addresses, access times, browser type, and language.</span></p>
        <p class="heading"><strong><em><span style="font-size:12pt;">Customer Information:</span></em></strong></p>
        <p></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Collected during the onboarding process, encompassing names, contact details, and organizational information.</span></p>
    </li>
</ul>
<p class="heading"><strong><span style="font-size:12pt;">Information Usage:</span></strong></p>
<p><strong><em></em></strong></p>
<p class="heading"><strong><em><span style="font-size:12pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Visitor Information:</span></em></strong></p>
<p></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Utilized to track engagement patterns and enhance overall user experience.</span></p>
        <p class="heading"><strong><em><span style="font-size:12pt;">Customer Information:</span></em></strong></p>
        <p></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Employed for diverse purposes, including processing requests, facilitating billing transactions, delivering information or services, and conducting marketing activities.</span></p>
    </li>
</ul>
<p class="heading"><strong><span style="font-size:12pt;">Information Sharing and Disclosure:</span></strong></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Personal Information is not traded or sold to third parties.</span></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Shared with third parties for essential services such as maintenance, web analytics, and secure payment processing.</span></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Disclosed in compliance with legal processes, court orders, or to safeguard Fleetio&apos;s rights.</span></p>
    </li>
</ul>
<p class="heading"><strong><em><span style="font-size:12pt;">Cross-Border Data Transfers:</span></em></strong></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Fleetio processes Personal Information in the United States and may transfer data across borders.</span></p>
    </li>
</ul>
<p class="heading"><strong><em><span style="font-size:12pt;">Data Retention:</span></em></strong></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Personal Information is retained for as long as necessary for its intended purpose or as required by law.</span></p>
    </li>
</ul>
<p class="heading"><strong><em><span style="font-size:12pt;">Security Measures:</span></em></strong></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Fleetio implements rigorous security measures, including encryption for sensitive data.</span></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Account access is fortified with individualized usernames and passwords.</span></p>
    </li>
</ul>
<p class="heading"><strong><em><span style="font-size:12pt;">Communication and Marketing:</span></em></strong></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Communication includes updates, legal documents, and dedicated customer support.</span></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Opt-out options are provided for marketing communication.</span></p>
    </li>
</ul>
<p class="heading"><strong><em><span style="font-size:12pt;">Breach Protocol:</span></em></strong></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Users are promptly notified of data breaches within 72 business hours.</span></p>
    </li>
</ul>
<p class="heading"><strong><em><span style="font-size:12pt;">Non-U.S. Users:</span></em></strong></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Fleetio adheres to GDPR rights for EU users.</span></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Complies with the EU-U.S. Privacy Shield Framework.</span></p>
    </li>
</ul>
<p class="heading"><strong><em><span style="font-size:12pt;">Cookie Policy:</span></em></strong></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Fleetio uses cookies for various purposes, including user preferences, analytics, and video tracking.</span></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Provides comprehensive information on cookies set by Fleetio and third parties.</span></p>
    </li>
</ul>
<p class="heading"><strong><em><span style="font-size:12pt;">Questions and Contact Information:</span></em></strong></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">Users are encouraged to reach out to Fleetio for any questions or clarifications regarding the privacy policy.&nbsp;</span></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;">
        <p><span style="font-size:12pt;">We are committed to ensuring the utmost transparency and privacy protection for our valued users.</span></p>
    </li>
</ul>`;

export default PrivacyPolicy;
