import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import PrivacyPolicy from '../components/PrivacyPolicy';
import FooterThree from '../components/FooterThree';
import NavbarThree from '../components/NavbarThree';

const CaseStudyDetails = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />

      {/* Navigation Bar */}
      <Breadcrumb title={'Privacy Policy'} />

      {/* Case Study Area */}
      <PrivacyPolicy />

      {/* Footer One */}
      <FooterThree />
    </>
  );
};

export default CaseStudyDetails;
