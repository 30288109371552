import React, { useState, useEffect } from 'react';
import {
  FaChevronRight,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { submitContactInfo } from '../redux/slices/common';
import { useDispatch, useSelector } from 'react-redux';

const FooterThree = () => {

  const dispatch = useDispatch();
  const { contactRes } = useSelector((state) => state.common);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');

  const handleSubscribe = (e) => {
    e.preventDefault();
    setLoading(true)
    console.log('Subscribed with email:', email);
    dispatch(submitContactInfo({
      email: email,
      name: 'subscribe',
      subject: 'subscribe',
      message: 'subscribe',
    }));
    // Add logic here to handle the subscription form data
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  useEffect(() => {

    if (contactRes && contactRes.status === 1 && loading) {
      setLoading(false);
      setSuccess(contactRes.message)
      console.log('contactRes', contactRes)
      setEmail('')
      console.log('contactRes', contactRes);
    } else if (contactRes && contactRes.status === -1) {
      setLoading(false);
      setSuccess(contactRes.message)
      toast.success('Please try again');
    }
  }, [contactRes]);

  return (
    <>

      {/* ================== Footer Two Start ==================*/}
      <footer className="footer-area bg-black mt-0 pd-top-100">
        {/* <Toaster position="top-bottom" reverseOrder={false} /> */}
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img
                    src="assets/img/logo/tracksense.png"
                    width="60"
                    alt="img"
                  />
                </div>
                <div className="details">
                  <p>Intelligent on every track</p>
                  {/* <ul className="social-media">
                    <li>
                      <a href="#">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaYoutube />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            {/* <div className="col-xl-3 col-lg-4 offset-xl-2 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Service</h4>
                <ul>
                  <li>
                    <Link to="/service">
                      <FaChevronRight /> Digital Marketing
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <FaChevronRight /> Video Editing
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <FaChevronRight /> Pc Repairs{' '}
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <FaChevronRight /> Web Development
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-xl-3 col-lg-4 offset-xl-1 col-md-6">
              <div className="widget widget_about">
                <h4 className="widget-title">Our Service</h4>
                <div className="details">
                  <p>Subscribe to new selector</p>
                  <form onSubmit={handleSubscribe}>
                    <div className="subscribe mt-4">
                      <input
                        type="email"
                        placeholder="E-mail"
                        required
                        value={email}
                        onChange={handleInputChange}
                      />

                      {
                        loading ? (<button className="btn btn-primary" type="button" disabled>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </button>) : (<button type="submit">
                          <FaChevronRight />
                        </button>)
                      }

                    </div>
                  </form>
                  <p>{success}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom bg-gray-black">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>© TrackSense.ai 2024 | All Rights Reserved</p>
              </div>
              <div className="col-md-6 text-lg-end">
                <a href="/terms-and-condition">Terms &amp; Condition</a>
                <a href="/privacy-policy">Privacy Policy</a>
                <a href="/contact">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterThree;
