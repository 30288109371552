let TermsAndPrivacy1 = `<p>Welcome to TrackSense.ai! These Terms of Service constitute a legally binding agreement between you and TrackSense.ai, governing your access to our cutting-edge tracking services. Please carefully review these terms before signing up or utilizing our
  services.</p>
<p>
  <span style="font-weight:600;color:black;">1. ACCOUNT TERMS:</span>
</p>
<ul>
  <li>To use TrackSense.ai, you must be 13 years or older. If you're between 13 and 18, a parent must assist in registering your account.</li>
  <li>Only human accounts are allowed; automated methods or "bots" are not permitted.</li>
  <li>Provide your full legal name, your business's legal name (if applicable), a valid email address, and any other requested information during the sign-up process. This information will be securely stored, and you reserve the right to manage personally
      identifiable information in your account.</li>
  <li>Safeguard your password; we are not liable for any loss or damage resulting from failure to comply with security obligations.</li>
  <li>Each login is intended for use by one person. Multiple users with a single login are not allowed.</li>
  <li>Use the service responsibly and legally. Do not violate laws, rules, or regulations, including copyright laws. Ensure that any information submitted complies with data protection, privacy, and security policies.</li>
  <li>Do not attempt to modify, decompile, or reverse engineer any software used by TrackSense.ai.</li>
  <li>The Service may evolve, and we reserve the right to modify it, add or remove features at any time.</li>
  <li>We may access, read, preserve, and disclose information as necessary to comply with laws, enforce the Terms of Service, detect fraud, address technical issues, or protect our rights and safety.</li>
</ul>
<p>
  <span style="font-weight:600;color:black;">2. TRACKING SERVICES, BILLING, AND PAYMENT:</span>
</p>
<ul>
  <li>Enjoy a limited trial period, after which a recurring service fee will apply.</li>
  <li>Your account will auto-renew at the end of each Service Period unless canceled explicitly by you or us.</li>
  <li>Non-refundable fees will be billed in advance. No refunds for partial use, upgrade/downgrade refunds, or account cancellations. Contact us immediately for billing-related inquiries or errors.</li>
  <li>We have a zero tolerance policy for chargebacks. Valid credit card disputes result in a permanent ban from using the Service.</li>
  <li>Your provided payment method will be charged automatically for any changes in the recurring service fee.</li>
  <li>Ensure accurate billing information, and we reserve the right to change prices with a 30-day notice.</li>
</ul>
<p>
  <span style="font-weight:600;color:black;">3. CANCELLATION AND TERMINATION:</span>
</p>
<ul>
  <li>You are responsible for properly canceling your account. All information may be immediately deleted upon cancellation; export data if needed.</li>
  <li>Abuse of the Service will lead to termination. TrackSense.ai determines what constitutes abuse, and termination is without prejudice to other remedies available by law or under these Terms.</li>
</ul>
<p>
  <span style="font-weight:600;color:black;">4. PRIVACY:</span>
</p>
<ul>
  <li>Refer to our Privacy Policy at
      <span style="color: rgba(41,100,170,var(--tw-text-opacity));">https://www.tracksense.ai/legal/privacy</span>for information on data collection and use.</li>
</ul>
<p>
  <span style="font-weight:600;color:black;">5. NO WARRANTIES OR REPRESENTATIONS:</span>
</p>
<ul>
  <li>The Service is provided "AS IS" and "AS AVAILABLE." We disclaim warranties of any kind, including accuracy, merchantability, fitness for a particular purpose, or non-infringement.</li>
  <li>You assume the risk of using the Service; we are not liable for any damage, including data loss, resulting from its use.</li>
  <li>Our liability, if any, is limited to amounts paid by you for the Service during the twelve (12) months preceding the event.</li>
</ul>
<p>
  <span style="font-weight:600;color:black;">6. INTELLECTUAL PROPERTY:</span>
</p>
<ul>
  <li>All information posted to the Service must comply with applicable copyright laws.</li>
  <li>We grant you a personal, non-transferable license to use the Service for your benefit.</li>
  <li>Do not copy, sell, or transfer the license to third parties.</li>
  <li>All rights to the Service, content, and software are exclusive to TrackSense.ai.</li>
</ul>
<p>
  <span style="font-weight:600;color:black;">7. INDEMNIFICATION:</span>
</p>
<ul>
  <li>You agree to indemnify and hold us harmless from any violation of these terms, including reasonable attorney fees.</li>
</ul>
<p>
  <span style="font-weight:600;color:black;">8. GOVERNING LAW AND VENUE:</span>
</p>
<ul>
  <li>These Terms are governed by the laws of the State of Delaware. Disputes shall be resolved through binding arbitration in Birmingham, Alabama, under the American Arbitration Association's auspices.</li>
</ul>
<p>
  <span style="font-weight:600;color:black;">9. NON-U.S. USERS:</span>
</p>
<ul>
  <li>If outside the U.S., you consent to the processing of personally identifiable information on secure servers within the U.S.</li>
</ul>
<p>
  <span style="font-weight:600;color:black;">10. NOTICES:</span>
</p>
<ul>
  <li>Notices may be given by email or posted on the Service.</li>
</ul>
<p>
  <span style="font-weight:600;color:black;">11. GENERAL:</span>
</p>
<ul>
  <li>If any provision is deemed invalid, the remainder of the Terms remains in effect.</li>
  <li>Failure to exercise any right does not constitute a waiver.</li>
  <li>Titles are for convenience and have no legal effect.</li>
  <li>You may not assign these Terms without our written consent.</li>
</ul>
<p>
  <span style="font-weight:600;color:black;">12. QUESTIONS:</span>
</p>
<ul>
  <li>Contact us with any questions about these Terms of Service.</li>
</ul>`;

const TermsAndPrivacy = `<p>Welcome to TrackSense.ai! We appreciate your interest in our cutting-edge tracking services. Before you embark on this journey with us, we urge you to carefully review and understand the intricacies of the following comprehensive Terms of Service. These terms serve as the foundational framework that governs the relationship between you and TrackSense.ai. Please feel free to reach out to us if you have any questions or concerns.</p>
<p class="heading">1. ACCOUNT TERMS:&nbsp;</p>
<p class="heading">1.1 Eligibility:&nbsp;</p>
<p>To access TrackSense.ai, users must be 13 years or older. If your age falls between 13 and 18, parental assistance is mandatory for the account registration process.&nbsp;</p>
<p class="heading">1.2 Account Information:&nbsp;</p>
<p>● During the sign-up process, you are required to furnish accurate and complete information.&nbsp;</p>
<p>● This includes your full legal name, the legal name of your business (if applicable), a valid email address, and any other details requested.&nbsp;</p>
<p>● Your information will be securely stored, and you retain the right to manage personally identifiable information within your account.</p>
<p class="heading">&nbsp;1.3 Security:&nbsp;</p>
<p>It is imperative to safeguard your password. TrackSense.ai holds no liability for any loss or damage resulting from a failure to comply with security obligations.&nbsp;</p>
<p class="heading">1.4 Responsible Use:</p>
<p>Utilize the service responsibly and legally. Adhere to all laws, rules, and regulations, including copyright laws. Any information submitted must comply with data protection, privacy, and security policies.&nbsp;</p>
<p class="heading">1.5 Service Evolution:&nbsp;</p>
<p>The service is dynamic and may evolve. We reserve the right to modify, add, or remove features at any time.&nbsp;</p>
<p class="heading">1.6 Information Access:&nbsp;</p>
<p>We may access, read, preserve, and disclose information as necessary to comply with laws, enforce the Terms of Service, detect fraud, address technical issues, or protect our rights and safety.&nbsp;</p>
<p class="heading">2. TRACKING SERVICES, BILLING, AND PAYMENT:&nbsp;</p>
<p class="heading">2.1 Trial Period:&nbsp;</p>
<p>Experience a limited trial period, after which a recurring service fee will apply.&nbsp;</p>
<p class="heading">2.2 Billing and Payment:&nbsp;</p>
<p>● Your account will auto-renew at the end of each service period unless explicitly canceled by you or us.&nbsp;</p>
<p>● Non-refundable fees are billed in advance, and no refunds are provided for partial use, upgrade/downgrade refunds, or account cancellations. Immediate contact is required for billing-related inquiries or errors.&nbsp;</p>
<p>● We enforce a zero-tolerance policy for chargebacks, and valid credit card disputes result in a permanent ban from using the Service.</p>
<p>● Your provided payment method will be charged automatically for any changes in the recurring service fee. Ensure accurate billing information, and we reserve the right to change prices with a 30-day notice.&nbsp;</p>
<p class="heading">3. CANCELLATION AND TERMINATION:&nbsp;</p>
<p>3.1 Account Cancellation:&nbsp;</p>
<p>You are responsible for properly canceling your account, and all information may be immediately deleted upon cancellation; hence, it is advisable to export data if needed.&nbsp;</p>
<p class="heading"class="heading">3.2 Termination:&nbsp;</p>
<p>Abuse of the service may lead to termination, with TrackSense.ai determining what constitutes abuse. Termination is without prejudice to other remedies available by law or under these Terms.&nbsp;</p>
<p class="heading">4. PRIVACY:&nbsp;</p>
<p class="heading">4.1 Privacy Policy:&nbsp;</p>
<p>Refer to our Privacy Policy for comprehensive information on data collection and use.&nbsp;</p>
<p class="heading">5. NO WARRANTIES OR REPRESENTATIONS:&nbsp;</p>
<p class="heading">5.1 Service Disclaimer:&nbsp;</p>
<p>● The service is provided &quot;AS IS&quot; and &quot;AS AVAILABLE,&quot; with disclaimers of any kind, including accuracy, merchantability, fitness for a particular purpose, or non-infringement.&nbsp;</p>
<p>● You assume the risk of using the Service, and we are not liable for any damage, including data loss, resulting from its use.</p>
<p>● Our liability, if any, is limited to amounts paid by you for the Service during the twelve (12) months preceding the event.&nbsp;</p>
<p class="heading">6. INTELLECTUAL PROPERTY:&nbsp;</p>
<p class="heading">6.1 Copyright Compliance:&nbsp;</p>
<p>All information posted must comply with applicable copyright laws.&nbsp;</p>
<p class="heading">6.2 License Agreement:&nbsp;</p>
<p>We grant you a personal, non-transferable license to use the Service.&nbsp;</p>
<p>Any copying, selling, or transferring of the license to third parties is strictly prohibited. All rights to the Service, content, and software are exclusive to TrackSense.ai.&nbsp;</p>
<p class="heading">7. INDEMNIFICATION:&nbsp;</p>
<p class="heading">7.1 Agreement to Indemnify:&nbsp;</p>
<p>You agree to indemnify and hold us harmless from any violation of these terms, including reasonable attorney fees.&nbsp;</p>
<p class="heading">8. GOVERNING LAW AND VENUE:&nbsp;</p>
<p class="heading">8.1 Jurisdiction:&nbsp;</p>
<p>These Terms are governed by the laws of the State of Delaware, and disputes shall be resolved through binding arbitration in Birmingham, Alabama, under the American Arbitration Association&apos;s auspices.&nbsp;</p>
<p class="heading">9. NON-U.S. USERS:&nbsp;</p>
<p class="heading">9.1 Data Processing Consent:&nbsp;</p>
<p>If you are outside the U.S., you consent to the processing of personally identifiable information on secure servers within the U.S.&nbsp;</p>
<p class="heading">10. NOTICES:</p>
<p class="heading">10.1 Communication:&nbsp;</p>
<p>Notices may be given by email or posted on the Service.&nbsp;</p>
<p class="heading">11. GENERAL:&nbsp;</p>
<p class="heading">11.1 Term Validity:&nbsp;</p>
<p>If any provision is deemed invalid, the remainder of the Terms remain in effect.&nbsp;</p>
<p class="heading">11.2 Rights Reservation:&nbsp;</p>
<p>Failure to exercise any right does not constitute a waiver. Titles are for convenience and have no legal effect. These Terms may not be assigned without our written consent.&nbsp;</p>
<p class="heading">12. QUESTIONS:&nbsp;</p>
<p class="heading">12.1 Contact Information:&nbsp;</p>
<p>For any questions about these Terms of Service, please contact us.&nbsp;</p>
<p>Thank you for selecting TrackSense.ai. We are eager to provide you with unparalleled tracking services and support on your journey</p>`;

export default TermsAndPrivacy;
