import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BannerFour = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 15000, // Change this value to your needs
  };
  return (
    <>
      {/* ================== BannerFour Start ==================*/}
      <Slider {...settings}>
        {/* first col */}
        <div className="banner-area bg-relative banner-area-3">
          <div className="container custom-container">
            <div className="bg-gray" style={{ background: '#ffffff' }}>
              <img
                className="img-right"
                src="assets/img/banner-4/wgif2.gif"
                alt="img"
                style={{ objectFit: 'contain', width: '25%' }}
              />
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 align-self-center">
                    <div className="banner-inner">
                      <h6
                        className="subtitle "
                        data-aos="fade-right"
                        data-aos-delay="100"
                        data-aos-duration="1500"
                      >
                        Real-time Business Insights
                      </h6>
                      <h2
                        className="title "
                        data-aos="fade-right"
                        data-aos-delay="250"
                        data-aos-duration="1500"
                      >
                        Interact with your business data through WhatsApp, and our AI BOT delivers role-based insights instantly.                        {/* <span style={{ color: '#007bc0' }}>AI</span> and Advanced
                        <span style={{ color: '#007bc0' }}> RBAC</span>. */}
                      </h2>
                      <a href="https://calendly.com/manikandans-ocm"
                        className="mt-3 btn btn-border-base"
                        data-aos="fade-right"
                        data-aos-delay="400"
                        data-aos-duration="1500"
                        target="_blank"
                        rel="noopener noreferrer">
                        Know More <FaPlus />
                      </a>



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* second */}
        <div className="banner-area bg-relative banner-area-3">
          <div className="container custom-container">
            <div className="bg-gray" style={{ background: '#ffffff' }}>
              <img
                className="img-right"
                src="assets/img/banner-4/new/1.png"
                alt="img"
                style={{ objectFit: 'contain', width: '45%' }}
              />
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 align-self-center">
                    <div className="banner-inner">
                      <h6
                        className="subtitle "
                        data-aos="fade-right"
                        data-aos-delay="100"
                        data-aos-duration="1500"
                      >

                        Robust, Resilient, Customizable
                      </h6>
                      <h2
                        className="title"
                        data-aos="fade-right"
                        data-aos-delay="250"
                        data-aos-duration="1500"
                      >
                        A powerful, adaptable SAAS platform with strong security and customization features
                        {/* Revolutionizing Navigation with{' '}
                        <span style={{ color: '#007bc0' }}>AI</span> and Advanced
                        <span style={{ color: '#007bc0' }}> RBAC</span>. */}
                      </h2>
                      <a href="https://calendly.com/manikandans-ocm"
                        className="mt-3 btn btn-border-base"
                        data-aos="fade-right"
                        data-aos-delay="400"
                        data-aos-duration="1500"
                        target="_blank"
                        rel="noopener noreferrer">
                        Know More <FaPlus />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Third col */}
        <div className="banner-area bg-relative banner-area-3">
          <div className="container custom-container">
            <div className="bg-gray" style={{ background: '#ffffff' }}>
              <img
                className="img-right"
                src="assets/img/banner-4/new/8.png"
                alt="img"
                style={{ objectFit: 'contain', width: '45%' }}
              />
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 align-self-center">
                    <div className="banner-inner">
                      <h6
                        className="subtitle "
                        data-aos="fade-right"
                        data-aos-delay="100"
                        data-aos-duration="1500"
                      >
                        Data-Powered Decision-Making
                      </h6>
                      <h2
                        className="title "
                        data-aos="fade-right"
                        data-aos-delay="250"
                        data-aos-duration="1500"
                      >
                        Our AI BOT analyzes your business daily, offering valuable performance insights for informed decisions
                        {/* <span style={{ color: '#007bc0' }}>AI</span> and Advanced
                        <span style={{ color: '#007bc0' }}> RBAC</span>. */}
                      </h2>
                      <a href="https://calendly.com/manikandans-ocm"
                        className="mt-3 btn btn-border-base"
                        data-aos="fade-right"
                        data-aos-delay="400"
                        data-aos-duration="1500"
                        target="_blank"
                        rel="noopener noreferrer">
                        Know More <FaPlus />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Slider >
      {/* ================== BannerFour End ==================*/}
    </>
  );
};

export default BannerFour;
