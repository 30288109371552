import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import TermsAndCondition from '../components/TermsAndCondition';
import FooterThree from '../components/FooterThree';
import NavbarThree from '../components/NavbarThree';

const CaseStudyDetails = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />

      {/* Navigation Bar */}
      <Breadcrumb title={'Terms & Condition'} />

      {/* Case Study Area */}
      <TermsAndCondition />

      {/* Footer One */}
      <FooterThree />
    </>
  );
};

export default CaseStudyDetails;
