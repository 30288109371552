import React from 'react';
import Marquee from 'react-fast-marquee';
const BrandAreaOne = () => {
  return (
    <div className="about-area bg-gray pt-5 pb-5">
      <div className="container">
        <div className="client-slider">
          <Marquee gradient={false}>
            <div className="thumb">
              <img src="ourPartners/ATrack.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/CalAmp.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Concox.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Galileosky.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/GlobalSat.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Laipac.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Meitrack.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Ruptela.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Sierra.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Starcom Systems.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Suntech.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Teltonika.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/ThinkRace.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Topflytech.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Trackimo.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Tramigo.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="ourPartners/Xexun.png" alt="img" />
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default BrandAreaOne;
