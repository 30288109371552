let serviceList_old = [
  {
    title: 'Mobile App Development',
    des: 'Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is',
    img: 'assets/img/service-icon/1.png',
  },
  {
    title: 'Content Marketing',
    des: 'Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is',
    img: 'assets/img/service-icon/2.png',
  },
  {
    title: 'E-commerce Solutions',
    des: 'Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is',
    img: 'assets/img/service-icon/3.png',
  },
  {
    title: 'Content Marketing',
    des: 'Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is',
    img: 'assets/img/service-icon/2.png',
  },
  {
    title: 'Mobile App Development',
    des: 'Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is',
    img: 'assets/img/service-icon/1.png',
  },

  {
    title: 'E-commerce Solutions',
    des: 'Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is',
    img: 'assets/img/service-icon/3.png',
  },
];

let serviceList1 = [
  {
    title: 'Real-Time Fleet Oversight, Anytime, Anywhere',
    des: 'Effortlessly monitor and control all assets—cars, buses, motorcycles, services, deliveries, heavy-duty trucks, taxis, cargo, and construction equipment—from your PC or mobile device.',
    img: 'assets/img/service-icon/1.png',
  },
  {
    title: 'Safeguard Your Fleet and Cargo with TrackSense',
    des: 'Every year, businesses suffer significant losses due to vehicle and cargo theft. TrackSense fleet management software serves as a powerful deterrent against theft while aiding in swift recovery.',
    img: 'assets/img/service-icon/2.png',
  },
  // {
  //   title: 'Safeguard Your Fleet and Cargo with TrackSense',
  //   des: 'Every year, businesses suffer significant losses due to vehicle and cargo theft. TrackSense fleet management software serves as a powerful deterrent against theft while aiding in swift recovery.',
  //   img: 'assets/img/service-icon/2.png',
  // },
  {
    title: 'Slash Insurance Costs with GPS Tracking',
    des: 'GPS tracking not only boosts security but may also cut insurance expenses. Qualify for potential discounts up to 12% by equipping your vehicles, potentially saving on premiums.',
    img: 'assets/img/service-icon/3.png',
  },
  {
    title: 'Trim Fuel Costs, Mitigate Liability',
    des: 'Harness GPS fleet tracking to curb speeding, idling, and unauthorized usage, effectively cutting fuel expenses. Utilize vehicle-specific reporting to incentivize fuel-efficient driving habits.',
    img: 'assets/img/service-icon/2.png',
  },
  {
    title: 'Cut Maintenance Costs with Scheduled Plans',
    des: 'TrackSense simplifies maintenance scheduling by mileage, hours, and time. Timely reports aid in necessary actions, potentially saving up to 10% in maintenance costs.',
    img: 'assets/img/service-icon/1.png',
  },
  {
    title: 'Instant ROI with TrackSense',
    des: 'TrackSense tracking software ensures immediate returns for customers. Our fleet management solutions allow for efficient labor monitoring, potentially slashing costs by up to 40%.',
    img: 'assets/img/service-icon/3.png',
  },
];

let serviceList = [
  {
    title: 'Real-Time Fleet Management',
    des: 'Effortlessly track and manage various assets, from cars and buses to heavy-duty trucks and construction equipment, in real-time through your PC or mobile device.',
    img: 'assets/serviceIcon/Real_time_fleet_management.png',
  },
  {
    title: 'Enhanced Fleet and Cargo Security',
    des: 'Prevent significant financial losses caused by vehicle or cargo theft. Our TrackSense fleet management software serves as a robust deterrent and aids in swift recovery in case of any incidents.',
    img: 'assets/serviceIcon/Enhanced_Fleet_and_cargo_management.png',
  },
  {
    title: 'Billing Dispute Resolution',
    des: 'Accurately document arrival and departure times at customer locations with fleet tracking data. Simplify and expedite the resolution of billing disputes effortlessly.',
    img: 'assets/serviceIcon/Billing_dispute_resolution.png',
  },
  {
    title: 'Optimized Fleet Efficiency',
    des: 'Utilize fleet tracking to analyze service-call durations and identify unproductive time slots. Optimize routes based on real-time traffic and weather conditions for improved efficiency.',
    img: 'assets/serviceIcon/Optimised_fleet_efficiency.png',
  },
  {
    title: 'Fuel Cost Reduction and Liability Management',
    des: 'Mitigate unnecessary fuel costs, speeding incidents, and unauthorized vehicle usage with GPS fleet tracking. Encourage fuel-efficient driving habits through vehicle-specific reporting.',
    img: 'assets/serviceIcon/Fuel_cost_reduction_and_Liability_management.png',
  },
  {
    title: 'Cost Savings',
    des: 'Witness immediate return on investment with TrackSense tracking software. Streamline labor costs and potentially reduce them by up to 40% using our efficient fleet management solutions.',
    img: 'assets/serviceIcon/cost_saving.png',
  },
];

export default serviceList;
