import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './redux/store';
import About from './pages/About';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';
import CaseStudyDetails from './pages/CaseStudyDetails';
import TermsAndCondition from './pages/TermsAndCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Contact from './pages/Contact';
import HomeFour from './pages/HomeFour';
import HomeOne from './pages/HomeOne';
import HomeThree from './pages/HomeThree';
import HomeTwo from './pages/HomeTwo';
import Service from './pages/Service';
import ServiceDetails from './pages/ServiceDetails';
import Team from './pages/Team';
import TeamDetails from './pages/TeamDetails';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import HomeFive from './pages/HomeFive';
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: 'ease',
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<HomeFour />} />
            <Route exact path="/index-1" element={<HomeOne />} />
            <Route exact path="/index-2" element={<HomeTwo />} />
            <Route exact path="/index-3" element={<HomeThree />} />
            {/* <Route exact path="/index-4" element={<HomeFour />} /> */}
            <Route exact path="/index-5" element={<HomeFive />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog-details" element={<BlogDetails />} />
            <Route
              exact
              path="/case-study-details"
              element={<CaseStudyDetails />}
            />
            <Route
              exact
              path="/terms-and-condition"
              element={<TermsAndCondition />}
            />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/service" element={<Service />} />
            <Route exact path="/service-details" element={<ServiceDetails />} />
            <Route exact path="/team" element={<Team />} />
            <Route exact path="/team-details" element={<TeamDetails />} />
          </Routes>
          <ScrollToTop smooth color="#246BFD" />
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
