import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const CareerAreaOne = () => {
  return (
    <>
      {/* career area start */}
      <div className="career-section pd-top-120 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                {/* <h6 className="sub-title">ABOUT US</h6> */}
                <h2 className="title">
                  Why Choose Tracksense.ai?

                  {/* The Power Of <span>Software</span> The Simplicity Of Solutions */}
                </h2>
              </div>
            </div>
          </div>
          <div className="career-wrap-area">
            <nav>
              <div
                className="nav nav-tabs career-nav-tab"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id="nav-mission-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-mission"
                  type="button"
                  role="tab"
                  aria-controls="nav-mission"
                  aria-selected="true"
                >
                  Advanced SaaS Solution
                </button>
                <button
                  className="nav-link"
                  id="nav-vision-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-vision"
                  type="button"
                  role="tab"
                  aria-controls="nav-vision"
                  aria-selected="false"
                >
                  Personalized AI
                </button>
                <button
                  className="nav-link"
                  id="nav-career-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-career"
                  type="button"
                  role="tab"
                  aria-controls="nav-career"
                  aria-selected="false"
                >
                  AI-Driven Insights
                </button>
                <button
                  className="nav-link"
                  id="nav-solution-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-solution"
                  type="button"
                  role="tab"
                  aria-controls="nav-solution"
                  aria-selected="false"
                >
                  Device Compatibility
                </button>
                <button
                  className="nav-link"
                  id="nav-alerts-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-alerts"
                  type="button"
                  role="tab"
                  aria-controls="nav-alerts"
                  aria-selected="false"
                >
                  Configurable Alerts
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-mission"
                role="tabpanel"
                aria-labelledby="nav-mission-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src="assets/img/about/new/1.png" alt="img" />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Advanced SaaS Solution</h3>
                      <p className="mb-3">
                        Our robust and resilient SAAS-based platform comes equipped with strong Role-Based Access Control (RBAC) and supports organizational and company hierarchies. We understand the importance of security and customization in your business operations.
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-vision"
                role="tabpanel"
                aria-labelledby="nav-vision-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img
                        src="assets/img/about/new/2.png"
                        alt="img"
                      />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Personalized AI</h3>
                      <p className="mb-3">
                        Tracksense.ai offers a unique feature - a custom GPT (Generative Pre-trained Transformer) for each organization and company. This means you can interact with your business data through WhatsApp, and our AI-powered BOT will provide real-time insights while adhering to strict role-based access.

                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-career"
                role="tabpanel"
                aria-labelledby="nav-career-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img
                        src="assets/img/about/new/3.png"
                        alt="img"
                      />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">AI-Driven Insights</h3>
                      <p className="mb-3">
                        Our AI BOT works tirelessly to analyze your business daily, providing you with valuable insights into its performance and opportunities for improvement. Get the edge you need to make informed decisions.
                      </p>
                     
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-solution"
                role="tabpanel"
                aria-labelledby="nav-solution-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img
                        src="assets/img/about/new/5.png"
                        alt="img"
                      />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Device Compatibility</h3>
                      <p className="mb-3">
                        Tracksense.ai supports nearly all top GPS devices available in the market, ensuring that you can seamlessly integrate our solution into your existing infrastructure.
                      </p>
                      {/* <div className="row">
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaCheckCircle /> Mistakes To Avoid to the dum
                            </li>
                            <li>
                              <FaCheckCircle /> Your Startup industry stan
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaCheckCircle /> Mistakes To Avoid to the dum
                            </li>
                            <li>
                              <FaCheckCircle /> Your Startup industry stan
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-alerts"
                role="tabpanel"
                aria-labelledby="nav-alerts-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img
                        src="assets/img/about/new/4.png"
                        alt="img"
                      />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Configurable Alerts</h3>
                      <p className="mb-3">
                        Stay in control with highly configurable alerts, including fence IN/OUT alerts, route deviation alerts, and major performance alerts supported by cutting-edge edge technologies.

                      </p>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* career area start */}
    </>
  );
};

export default CareerAreaOne;
