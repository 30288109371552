import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { toast, Toaster } from 'react-hot-toast';
import { submitContactInfo } from '../redux/slices/common';
import { useDispatch, useSelector } from 'react-redux';





const ContactMain = () => {
  const form = useRef();
  const dispatch = useDispatch();
  const { contactRes } = useSelector((state) => state.common);
  const [info, setInfo] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    // Please See Documentation for more information
    setLoading(true);
    dispatch(submitContactInfo(info));
    console.log('info', info);
    // emailjs
    //   .sendForm(
    //     'service_yipk4xg', //YOUR_SERVICE_ID
    //     'template_71bgc2q', //YOUR_TEMPLATE_ID
    //     form.current,
    //     'cwf8kROl5o3__96Ti' //YOUR_PUBLIC_KEY
    //   )
    //   .then(
    //     (result) => {
    //       if (result.text === 'OK') {
    //         toast.success('Massage Sent Successfully!');
    //         form.current[0].value = '';
    //         form.current[1].value = '';
    //         form.current[2].value = '';
    //         form.current[3].value = '';
    //       }
    //     },
    //     (error) => {
    //       if (error.text !== 'OK') {
    //         toast.success('Massage Not Sent!');
    //       }
    //     }
    //   );
  };

  useEffect(() => {
    if (contactRes && contactRes.status === 1 && loading) {
      setLoading(false);
      toast.success('Massage Sent Successfully!');
      setInfo(
        {
          name: '',
          email: '',
          subject: '',
          message: '',
        }
      )
      console.log('contactRes', contactRes);
    } else if (contactRes && contactRes.status === -1) {
      setLoading(false);
      toast.success('Please try again');
    }
  }, [contactRes]);

  return (
    <>
      {/* ================= Contact Main start =================*/}
      <Toaster position="top-center" reverseOrder={false} />
      <div className="contact-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="contact-page-inner bg-gray">
            <div className="section-title mb-4 pb-2">
              <h2 className="title">Direct contact us? </h2>
              <p className="content mb-0">
                Thank you for considering our logistics solutions. Whether you have inquiries, need assistance, or want to discuss your specific logistics requirements, we're here to help. Please feel free to contact us using the details provided below.
              </p>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div className="row">
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="name"
                      name="name"
                      value={info?.name}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Your Name."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="email"
                      name="email"
                      onChange={handleChange}
                      type="email"
                      value={info?.email}
                      placeholder="Enter Your Email."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="subject"
                      name="subject"
                      onChange={handleChange}
                      value={info?.subject}
                      type="text"
                      placeholder="Enter Your Subject."
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="single-input-inner">
                    <textarea
                      name="message"
                      id="massage"
                      onChange={handleChange}
                      value={info?.message}
                      cols="1"
                      rows="5"
                      placeholder="Enter Your Massage ..."
                      required
                    />
                  </div>
                </div>
                <div className="col-12 text-center">
                  {
                    loading ? (
                      <button className="btn btn-base border-radius-5" type="submit" disabled>
                        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        <span className="visually-hidden" role="status">Loading...</span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-base border-radius-5"
                        type="submit"
                      >
                        Send Message
                      </button>
                    )
                  }

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* contact list */}
      <div className="contact-page-list">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="media single-contact-list">
                <div className="media-left">
                  <img src="assets/img/icon/13.svg" alt="img" />
                </div>
                <div className="media-body">
                  <h5>Contacts us</h5>
                  <h6>84387 62921</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="media single-contact-list">
                <div className="media-left">
                  <img src="assets/img/icon/14.svg" alt="img" />
                </div>
                <div className="media-body">
                  <h5>Your Email</h5>
                  <h6>contact@tracksense.ai</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="media single-contact-list">
                <div className="media-left">
                  <img src="assets/img/icon/15.svg" alt="img" />
                </div>
                <div className="media-body">
                  <h5>Location</h5>
                  <h6>Tidel Park, Coimbatore</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Map  */}
      <div className="contact-g-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.0912787609363!2d77.01596427486082!3d11.031778189132952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8578e89a4e04f%3A0x5f10fc5a43b95850!2sTidel%20Park%2C%20Peelamedu%2C%20B.R.%20Puram%20Industrial%20Estate%2C%20Coimbatore%2C%20Tamil%20Nadu%20641014!5e0!3m2!1sen!2sin!4v1705338375420!5m2!1sen!2sin"  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

      {/* ================= Contact Main End =================*/}
    </>
  );
};

export default ContactMain;
