import React from 'react';
import { FaCheck } from 'react-icons/fa';

const PricingAreaOne = () => {
  return (
    <>
      {/* Pricing Area One start */}
      <div className="pricing-area bg-gray pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">Plans</h6>
            <h2 className="title">
            All-in-one solution for a profitable tracking business.
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing-inner style-3">
                {/* <h2 className="mb-3">
                  $19 <sub>/mo</sub>
                </h2> */}
                <h5>Pay-Per-Use</h5>
                <ul>
                  <li>
                    <FaCheck />
                    <strong>Billing Model:</strong> Charges based on actual usage (tracked assets, vehicles, AI queries).
                  </li>
                  <li>
                    <FaCheck />
                    <strong>Flexibility:</strong>Pay only for what's used; ideal for fluctuating needs.

                  </li>
                  <li>
                    <FaCheck />
                    <strong>Cost Control:</strong>Effectively monitor and manage expenses.

                  </li>
                  <li>
                    <FaCheck />
                    <strong>Scalability:</strong>Easily adjust usage to meet business needs.
                  </li>
                  {/* <li className="hide">
                    <FaCheck />
                    Social Media Management
                  </li>
                  <li className="hide">
                    <FaCheck />
                    Video &amp; Animation Production{' '}
                  </li> */}
                </ul>
                <a
                  className="btn btn-black border-radius border-radius-0 w-100"
                  href="https://calendly.com/manikandans-ocm"
                  target="_blank"
                >
                  know more
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing-inner style-3 price-active">
                {/* <h2 className="mb-3">
                  $19 <sub>/mo</sub>
                </h2> */}
                <h5>White Label</h5>
                <ul>
                  <li>
                    <FaCheck />
                    <strong>Reseller Focus:</strong>
                    Rebrand Tracksense.ai for your business.
                  </li>
                  <li>
                    <FaCheck />
                    <strong>Custom Branding:</strong>
                    Elevate your brand identity through customization.
                  </li>
                  <li>
                    <FaCheck />
                    <strong>Partner Support:</strong>
                    We provide training, marketing, and ongoing assistance.
                  </li>
                  <li>
                    <FaCheck />
                    <strong>Pricing Structure:</strong>
                    Set pricing and strategy for your customers.
                  </li>
                  
                </ul>
                <a
                  className="btn btn-black border-radius border-radius-0 w-100"
                  href="https://calendly.com/manikandans-ocm"
                  target="_blank"
                >
                 know more
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing-inner style-3">
                {/* <h2 className="mb-3">
                  $19 <sub>/mo</sub>
                </h2> */}
                <h5>Custom Package</h5>
                <ul>
                  <li>
                    <FaCheck />
                    <strong>Tailored Solutions:</strong>
                    Craft unique enterprise solutions.
                  </li>
                  <li>
                    <FaCheck />
                    <strong>Scalability and Flexibility:</strong>
                    Customize features, AI, and support.
                  </li>
                 
                  <li>
                    <FaCheck />
                    <strong>Personalized Pricing:</strong>
                    Pricing tailored to project scope and complexity.
                  </li>
                  <li>
                    <FaCheck />
                    <strong>Dedicated Support:</strong>
                    Clients receive a dedicated account manager and tech assistance
                  </li>
                  
                </ul>
                
                <a
                  className="btn btn-black border-radius border-radius-0 w-100"
                  href="https://calendly.com/manikandans-ocm"
                  target="_blank"
                >
                 know more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pricing Area One start */}
    </>
  );
};

export default PricingAreaOne;
