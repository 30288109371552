import React from "react";
import BlogDetailsArea from "../components/BlogDetailsArea";
import BlogDetailsArea2 from "../components/BlogDetailsArea2";
import BlogDetailsArea3 from "../components/BlogDetailsArea3";
import Breadcrumb from "../components/Breadcrumb";
import FooterThree from "../components/FooterThree";
import NavbarThree from "../components/NavbarThree";
import { useSearchParams } from 'react-router-dom';

const BlogDetails = () => {
  const [searchParams] = useSearchParams();
  const qp = searchParams.get('blog'); // Replace 'yourQueryParam' with the name of your query parameter
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />

      {/* Navigation Bar */}
      {/* <Breadcrumb title={"Blog Details"} /> */}

      {/* Blog Details Area */}

      {qp === 'rise-of-ai-logistics' ? <BlogDetailsArea /> : qp === 'last-mile-delivery' ? <BlogDetailsArea2 /> : qp === 'supply-chain' ? <BlogDetailsArea3 /> : <h2>No blogs to read</h2>}



      {/* Footer One */}
      <FooterThree />
    </>
  );
};

export default BlogDetails;
