/* eslint-disable object-shorthand */


import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import TermsAndPrivacy from '../../scripts/Terms&Privacy';
import PrivacyPolicy from '../../scripts/PrivacyPolicy';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  termsAndConditionRes: undefined,
  contactRes: null,
};

const slice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Terms and Privacy
    getTermsAndConditionSuccess(state, action) {
      state.isLoading = false;
      state.termsAndConditionRes = action.payload;
    },

    getPrivacyPolicySuccess(state, action) {
      state.isLoading = false;
      state.privacyPolicyRes = action.payload;
    },

    submitContactInfoSuccess(state, action) {
      state.isLoading = false;
      state.contactRes = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getTermsAndCondition() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getTermsAndConditionSuccess(TermsAndPrivacy));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPrivacyPolicy() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getPrivacyPolicySuccess(PrivacyPolicy));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function submitContactInfo(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`https://me8a63wzki.execute-api.us-east-1.amazonaws.com/dev/api/v1/contactRequests`,data);
      dispatch(slice.actions.submitContactInfoSuccess(response.data));
      console.log(response)
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearError() {
  return async (dispatch) => {
    dispatch(slice.actions.hasError(null));
  };
}
